import React from "react";

class Checkbox extends React.Component {
    constructor(props) {
        super(props);

        let lIsChecked = props.shouldCheck;

        this.state = {
            filters: [],
            isLoading: true,
            item: props.entity,
            prefix: props.prefix,
            type: props.type,
            isChecked: lIsChecked,
            shouldCheck: props.shouldCheck,
            updateFunction: props.update
        };

        this.handleCBChange = this.handleCBChange.bind(this);
    }

    handleCBChange() {
        window.location = "/suche/q=" + this.state.item.vars + "&p=1";
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.shouldCheck !== this.state.isChecked) {
            this.setState({isChecked: nextProps.shouldCheck});
            return true;
        }
        return false;
    }

    render() {
        return (
            <div className="custom-control custom-checkbox" key={this.state.id}>
                <input id={this.state.prefix + "_" + this.state.type + "_" + this.state.item.id} type="checkbox"
                       className="custom-control-input" name={this.state.prefix} value={this.state.item.id}
                       onClick={this.handleCBChange}/>
                <label className="custom-control-label"
                       htmlFor={this.state.prefix + "_" + this.state.type + "_" + this.state.item.id}>{this.state.item.name}</label>
            </div>
        );
    }
}

export default Checkbox;