import React from "react";
import {InputGroup} from "react-bootstrap";
import ACcompany from "./../Inputs/ACcompany";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'


class Searchblock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [],
            activeFilters: {search: props.activeFilters.search, location: props.activeFilters.location},
            isLoading: true,
            isLoadingState: props.buttonIsLoadingState,
            updateFunction: props.updateFunction,
            frameInputAttach: props.inputAttach,
        };
        this.updateFilters = this.updateFilters.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps.buttonIsLoadingState !== this.state.isLoadingState) {
            this.setState({isLoadingState: nextProps.buttonIsLoadingState});
            return true;
        }
        return false;
    }

    updateFilters(data) {
        let activeFilter = this.state.activeFilters;

        if (data.hasOwnProperty('search')) {
            activeFilter.search = data.search;
        }

        if (data.hasOwnProperty('city')) {
            activeFilter.location = data.city;
        }

        this.setState({activeFilters: activeFilter, isLoadingState: true});
        this.state.updateFunction({inputs: this.state.activeFilters, buttonIsLoadingState: true});
    }

    render() {
        let buttonIsLoading = '';
        /*
        if(this.state.isLoadingState) {
            buttonIsLoading = (<><FontAwesomeIcon icon={faSpinner} pulse />&nbsp;</>);
        }*/
        return (
            <div className="container mainsearch">
                <div className="card shadow-xs border-darker atlas-input-autocomplete-results-anchor mb-0">
                    <div className="card-body p-3">
                        <div className="row">
                            <div className="col-12 pr-0">
                                <div className="input-group input-group-merge overflow-initial">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="basic-addon1"><FontAwesomeIcon
                                                icon={faSearch}/></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <ACcompany update={this.updateFilters} current={this.state.activeFilters.search} attachInput={this.attachInputs}/>
                                        <InputGroup.Append className="pr-3">
                                            <div className="input-group-text border-0">
                                                <button type="submit" className="btn btn-primary btn-search">
                                                    {buttonIsLoading}
                                                    Suchen
                                                </button>
                                            </div>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Searchblock;