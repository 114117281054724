import React from "react";
import Checkbox from "./Inputs/Checkbox";
import Service from "../../../REST/Client";

class Filterlist extends React.Component {
    constructor(props) {
        super(props);
        this.currentFilters = props.initialFilters.filters;
        if (this.currentFilters.length === 0) {
            this.currentFilters = {city: [], jobs: [], industry: []};
        }

        this.state = {
            filters: [],
            activeFilters: this.currentFilters,
            isLoading: true,
            updateFunction: props.updateFunction,
        };


        this.service = new Service();
        this.updateFilters = this.updateFilters.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
    }

    componentDidMount() {
        this.service.call('GET', 'v1/search/landing/filters/', null, json => {
            this.setState({
                isLoading: false,
                filters: json,
            })
        });
    }

    updateFilters(data) {
        if (data.mode > 0) {
            if (this.state.activeFilters.hasOwnProperty(data.filter)) {
                let keyExists = false;
                this.state.activeFilters[data.filter].map(item => {
                    if (item === data.value) {
                        keyExists = true;
                    }
                    return true;
                });

                let localFilterSet = this.state.activeFilters;
                if (!keyExists) {
                    localFilterSet[data.filter].push(data.value);
                } else {
                    localFilterSet[data.filter] = data.value;
                }
                this.currentFilters = localFilterSet;
                this.setState({activeFilters: localFilterSet});
            }
        } else {
            if (this.state.activeFilters.hasOwnProperty(data.filter)) {
                let localFilterSet = this.state.activeFilters;

                localFilterSet[data.filter] = localFilterSet[data.filter].filter((value, index, arr) => {
                    return value !== data.value;
                });

                this.currentFilters = localFilterSet;
                this.setState({activeFilters: localFilterSet});
            }
        }

        this.state.updateFunction({filters: this.state.activeFilters});
    }

    resetFilters() {
        this.currentFilters = {type: [], start: [], size: [], industry: []};
        this.setState({activeFilters: this.currentFilters});
        this.state.updateFunction({filters: {reset: 1}});
        this.forceUpdate();
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div>
                    {this.state.isLoading ? '' : ''}
                </div>
            );
        } else {
            return (
                <div className="card card-transparent">
                    <div className="card-header border-0 mb-0 py-0 px-0">
                        <h4 className="h5 mb-0 card-header-title">Stadt</h4>
                    </div>
                    <div className="card-body small pb-2 px-0">
                        {this.state.filters.city.map((item, i) => (<Checkbox key={i} entity={item} prefix="filter"
                                                                             shouldCheck={this.currentFilters.city.includes(item.key)}
                                                                             type="city"
                                                                             update={this.updateFilters}/>))}
                    </div>
                    <div className="card-header border-0 mb-0 py-0 px-0">
                        <h4 className="h5 mb-0 card-header-title">Beruf</h4>
                    </div>
                    <div className="card-body small pb-2 px-0">
                        {this.state.filters.jobs.map((item, i) => <Checkbox key={i} entity={item} prefix="filter"
                                                                             shouldCheck={this.currentFilters.jobs.includes(item.key)}
                                                                             type="job"
                                                                             update={this.updateFilters}/>)}
                    </div>
                    <div className="card-header border-0 mb-0 py-0 px-0">
                        <h4 className="h5 mb-0 card-header-title">Branche</h4>
                    </div>
                    <div className="card-body small pb-2 px-0">
                        {this.state.filters.industry.map((item, i) => <Checkbox key={i} entity={item} prefix="filter"
                                                                                shouldCheck={this.currentFilters.industry.includes(item.key)}
                                                                                type="industry"
                                                                                update={this.updateFilters}/>)}
                    </div>
                </div>
            );
        }
    }
}

export default Filterlist;