import React from "react";
import Service from "../REST/Client";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.min.css';
import Grid from "./Results/Landing/Grid";
import Searchblock from "./Form/Landing/Searchblock";
import Filterlist from "./Form/Landing/Filterlist";

class FrameLanding extends React.Component {
    constructor() {
        super();
        let currentFilter = {filters: [], properties: [], radius: 5, page: 1};
        this.location = undefined;
        this.property = undefined;
        this.page = 1;
        this.lookUpData = this.lookUpData.bind(this);
        this.pageUpdateCallback = this.pageUpdateCallback.bind(this);
        this.rangeUpdateCallback = this.rangeUpdateCallback.bind(this);
        this.frameInputAttach = this.frameInputAttach.bind(this);
        this.handeleRedirectButtonDelegate = this.handeleRedirectButtonDelegate.bind(this);
        this.loadingDoneState = this.loadingDoneState.bind(this);
        this.loc = window.location.href;
        this.typeaheads = {search: null, location: null};
        this.redirectUrl = "/suche/";
        this.filters = {filters: [], properties: [], radius: 5, page: 1};

        this.state = {
            allowNew: false,
            isLoading: false,
            multiple: false,
            formFilters: currentFilter,
            searchblockKey: 1,
            currentCC: ""
        }
        this.service = new Service();
    }

    pageUpdateCallback(page) {
        let activeFilter = this.state.formFilters;
        if(activeFilter.page !== page) {
            activeFilter.page = page;
            this.setState({formFilters: activeFilter});
            this.createURI();
        }
    }

    rangeUpdateCallback(range) {
        let activeFilter = this.state.formFilters;
        activeFilter.range = range;
        this.setState({formFilters: activeFilter});
        this.createURI();
    }

    loadingDoneState() {
    }

    lookUpData(data) {
        let activeFilter = this.filters;
        if (data.hasOwnProperty('inputs')) {
            if (data.inputs.hasOwnProperty('location')) {
                if(data.inputs.location === -1) {
                    data.inputs.location = -1;
                }
                else if(data.inputs.location !== undefined) {
                    if (data.inputs.location.length > 0) {
                        data.inputs.location = data.inputs.location[0];
                        data.inputs.location.description = null
                    }
                } else {
                    data.inputs.location = [];
                }
            }
            activeFilter.properties = data.inputs;

            this.location = data.inputs.location;
            this.property = data.inputs.search;
            activeFilter.page = 1;
            this.page = 1;

            this.filters = activeFilter;
        }
        if (data.hasOwnProperty('filters')) {
            activeFilter.filters = data.filters;
            activeFilter.page = 1;
            this.filters = activeFilter;
            this.page = 1;
        }

        this.filters = activeFilter;
        this.createURI(activeFilter);
    }

    frameInputAttach(input) {
        if(input.hasOwnProperty('search')) {
            this.typeaheads.search = input.search;
        }
        if(input.hasOwnProperty('location')) {
            this.typeaheads.location = input.location;
        }
    }

    createURI(filterSet) {

        if (filterSet === undefined) {
            filterSet = this.state.formFilters;
        }

        if (filterSet.properties.hasOwnProperty("location")) {
            if (filterSet.properties.location.length > 0) {
                filterSet.properties.location = filterSet.properties.location[0];
                filterSet.properties.location.description = null
            }
        }

        let linkData = btoa(JSON.stringify(filterSet));
        this.redirectUrl = "/suche/q=" + linkData + "&p=" + filterSet.page;
        this.redirectToSearch();
    }

    redirectToSearch() {
        window.location = this.redirectUrl;
    }

    handeleRedirectButtonDelegate() {
        this.createURI(this.filters);
        this.redirectToSearch();
    }

    componentDidMount() {
        this.service.call('GET', 'v1/search/landing/count/', null, json => {
            this.setState({
                currentCC: json.result,
            });
        });
    }

    render() {
        let lCounter = 0;
        let wScounter = window.localStorage.getItem('atlasCounter');
        if(wScounter !== null) {
            lCounter = wScounter;
        }

        let paragraph = (<>
            Entdecke {lCounter} Arbeitgeber und finde Deinen
            Ausbildungsplatz.
        </>);
        let filterSidebar = "";
        let modalFilter = "";
        let mql = window.matchMedia('(max-width: 992px)');
        if (!mql.matches) {
            filterSidebar = (
                <div className="col-12 col-lg-3 d-none d-lg-block">
                    <Filterlist updateFunction={this.lookUpData} initialFilters={this.state.formFilters}/>
                </div>
            );
        } else {
            filterSidebar = (
                <div className="col-12 col-lg-3 d-none d-lg-block"></div>
            );
            modalFilter = (
                <div className="modal fade fixed-right" id="sidebarModalSearch" tabIndex="-1" role="dialog"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-vertical" role="document">
                        <div className="modal-content">
                            <div className="w-100 px-5 pt-5">
                                <h3>Filter</h3>
                                <button type="button" className="close float-right" data-dismiss="modal"
                                        aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="px-5 pt-5">
                                <Filterlist updateFunction={this.lookUpData} initialFilters={this.state.formFilters}/>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="searchframe landingsearch">
                <div className="header bg-dark bg-cover bg-cover-top header-gradient">
                    <div className="container">
                        <div className="header-body border-0 py-5 mb-4 py-md-6 mb-md-0">
                            <div className="row align-items-end">
                                <div className="col">
                                    <p className="h6 header-pretitle text-muted mb-3">AusbildungsAtlas.de</p>
                                    <h1 className="display-4 text-white mb-3 mb-md-2">Das Verzeichnis der
                                        Ausbildungsbetriebe</h1>
                                    <p className="text-white mb-md-0">{paragraph}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Searchblock key={this.state.searchblockKey} updateFunction={this.lookUpData} activeFilters={this.state.formFilters.properties} inputAttach={this.frameInputAttach} redirectFunction={this.handeleRedirectButtonDelegate}/>
                <section className="py-5">
                <div className="container py-5">
                    <div className="row">
                        {filterSidebar}
                        <div className="col-12 col-lg-9">
                            <Grid key={this.state.s} filters={this.state.formFilters} location={this.location} search={this.property}
                                  page={this.page}
                                  pageUpdateCallback={this.pageUpdateCallback}
                                  rangeUpdateCallback={this.rangeUpdateCallback}
                                  resetFunctionCallback={this.lookUpData}
                                  loadingUpdateCallback={this.loadingDoneState}/>
                        </div>
                    </div>
                </div>
                </section>
                {modalFilter}
            </div>
        );
    }
}

export default FrameLanding;