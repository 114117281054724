import React from "react";
import {AsyncTypeahead, Highlighter} from "react-bootstrap-typeahead";
import Service from "../../../REST/Client";

class ACcity extends React.Component {
    constructor(props) {
        super(props);
        let lSelection = [];
        if(props.current !== undefined) {
            if(Array.isArray(props.current)) {
                lSelection = props.current;
            } else {
                if(props.current === -1) {
                    lSelection = [];
                } else {
                    lSelection = [props.current];
                }
            }

            if(JSON.stringify(props.current) === "{}") {
                lSelection = [];
            }
        }
        this.state = {
            allowNew: false,
            isLoading: false,
            multiple: false,
            cities: [],
            current: null,
            selected: lSelection,
            updateFunction: props.update,
            attachInput: props.attachInput,
        };
        this.service = new Service();
        this.typeahead = AsyncTypeahead;
    }

    _handleSearchCity = (query) => {
        this.setState({isLoading: true});
        this.service.call('GET', `v1/search/suggestions/city/?query=${query}`, null, json => {
            this.setState({
                isLoading: false,
                cities: json.items.cities,
            });
        });
    };

    handleACChange(selected) {
        let value = {city: -1};
        if(selected.length > 0) {
            value = {city: selected};
        }
        this.setState({current: value, selected: selected});
        this.state.updateFunction(value);
    }

    render() {
        return (
            <AsyncTypeahead
                id="ip-city"
                style={{flex: '1 1 0', display: 'flex'}}
                ref={(typeahead) => this.typeahead = typeahead}
                emptyLabel="Keine Ergebnisse"
                searchText="Suche läuft..."
                promptText=""
                inputProps={{
                    autoComplete: "new-password",
                    spellCheck: "false",
                    autoCorrect: "off",
                    autoCapitalize: "off",
                    className: "data-hj-whitelist"
                }}
                options={this.state.cities}
                labelKey={option => `${option.name}`}
                minLength={2}
                onSearch={this._handleSearchCity}
                onChange={(selected) => {
                    this.handleACChange(selected);
                }}
                onKeyDown={(e, options) => {
                    if (e.keyCode === 13) {
                        if (this.currentValue !== "") {
                            if (this.state.cities.length > 0) {
                                this.handleACChange([this.state.cities[0]]);
                                this.typeahead.activeIndex = 0;
                                this.typeahead.getInstance().blur();
                            }
                        }
                    }
                }}
                clearButton={true}
                placeholder="Stadt"
                isLoading={false}
                selected={this.state.selected}
                renderMenuItemChildren={(option, props) => (
                    <span key={option.id} city={option}>
                        <Highlighter search={props.text}>
                            {option.name}
                        </Highlighter>
                    </span>
                )}
            />
        );
    }
}

export default ACcity;