import React from "react";
import {AsyncTypeahead, Highlighter} from "react-bootstrap-typeahead";
import Service from "../../../REST/Client";


class ACcompany extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        let lSelection = [];
        if(props.current !== undefined) {
            if(Array.isArray(props.current)) {
                lSelection = props.current;
            } else {
                lSelection = [props.current];
            }
        }

        this.state = {
            allowNew: false,
            isLoading: false,
            multiple: false,
            options: [],
            current: null,
            updateFunction: props.update,
            selected: lSelection
        };
        this.service = new Service();
        this.typeahead = AsyncTypeahead;
        this.currentValue = "";

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    _handleSearch = (query) => {
        this.setState({isLoading: true});
        this.service.call('GET', `v1/search/suggestions/job_company/?query=${query}`, null, json => {
            this.setState({
                isLoading: false,
                options: json.items.jobs.concat(json.items.companies),
            })
        });
    };

    handleACChange(selected) {
        this.setState({current: selected, selected: selected});
        let value = {search: selected};
        this.state.updateFunction(value);
    }

    handleInputChange(input, e) {
        //console.log(input);
        let value = {search: [{id: null, name: input, type: "mixed"}]};
        this.setState({current: value});
        this.currentValue = input;
        setTimeout(() => {
        }, 100);
    }

    handleKeyDown(e) {
        if (e.keyCode === 13) {
            if (this.currentValue !== "") {
                e.preventDefault();
                this.typeahead.getInstance().blur();
                this.state.updateFunction(this.state.current);
            }
        }
    }

    render() {
        return (
            <AsyncTypeahead
                style={{flex: '1 1 0', display: 'flex'}}
                ref={(typeahead) => this.typeahead = typeahead}
                id="ac-company"
                options={this.state.options}
                emptyLabel="Keine Ergebnisse"
                searchText="Suche läuft..."
                promptText=""
                inputProps={{
                    autoComplete: "new-password",
                    spellCheck: "false",
                    autoCorrect: "off",
                    autoCapitalize: "off",
                    className: "data-hj-whitelist"
                }}
                labelKey={option => `${option.name}`}
                minLength={2}
                onSearch={this._handleSearch}
                onInputChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
                onChange={(selected) => {
                    this.handleACChange(selected);
                }}
                clearButton={true}
                placeholder="Beruf oder Unternehmen"
                isLoading={false}
                selected={this.state.selected}
                renderMenuItemChildren={(option, props) => (
                    <span key={option.id} city={option}>
                      <Highlighter search={props.text}>
                            {option.name}
                        </Highlighter>
                    </span>
                )}
            />
        );
    }
}

export default ACcompany;