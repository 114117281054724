import React from "react";
import Tile from "./Tile";
import Service from "../../../REST/Client";
import AtlasPagination from "./Pagination";
import TileElevated from "./TileElevated";

class Grid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: props.filters,
            isLoading: true,
            data: {
                count: 0,
                items: []
            },
            page: props.page,
            radius: 5
        };

        if(Array.isArray(props.filters.properties)) {
            props.filters.properties = {search: [], location: []};
        }
        this.filters = props.filters;
        this.location = undefined;

        this.pageUpdateCallback = props.pageUpdateCallback;
        this.rangeUpdateCallback = props.rangeUpdateCallback;
        this.resetCallback = props.resetFunctionCallback;
        this.loadingUpdateCallback = props.loadingUpdateCallback;
        this.pageUpdate = this.pageUpdate.bind(this);
        this.rangeUpdate = this.rangeUpdate.bind(this);
        this.resetUpdate = this.resetUpdate.bind(this);

        this.properties = props.filters.properties;

        this.service = new Service();
    }

    resetUpdate() {
        this.resetCallback({filters: {reset: 1}});
        this.setState({filters: []});
    }

    pageUpdate(page) {
        if(parseInt(this.filters.page) !== page) {
            this.pageUpdateCallback(page);
            this.filters.page = page;
            this.setState({filters: this.filters});
        }
    }

    rangeUpdate(range) {
        this.rangeUpdateCallback(range);
        this.filters.radius = range;
        this.isLoading = true;
        this.setState({filters: this.filters});
    }

    componentDidMount() {
        this.service.call('POST', 'v1/search/landing/', this.filters,
            json => {
                this.setState({
                    isLoading: false,
                    data: json,
                });
                this.loadingUpdateCallback();
            });
    }

    render() {
        let lClassname = {opacity: .25};

        if(!this.state.isRefreshing) {
            lClassname = {opacity: 1};
        }

        if (this.state.isLoading) {
            return (
                <div>
                    Suche wird geladen...
                </div>
            );
        } else {
            let results = <></>;
            if(this.state.data.premium.items.length === 0 && this.state.data.results.items.length === 0 && this.state.data.outofbounds.items.length === 0) {
                results = (<>
                    <div className="card" style={{opacity: 1}}>
                        <div className="card-body">
                            <h4 className="mb-4">Keine Ergebnisse</h4>
                            <p className="mb-4">
                                Leider haben wir zu Deinen Suchkriterien keine Unternehmen gefunden. <span role="img" aria-label="Traurig">😭</span>
                            </p>
                            <button type="reset" className="btn btn-white text-primary" onClick={this.resetUpdate}><i className="icon-refresh-bold"></i> <span>Suche zurücksetzen</span></button>
                        </div>
                    </div>
                </>);
            } else {
                results = (
                    <>
                        {this.state.data.premium.items.length > 0 && this.state.page < 2
                            ? (
                                <>
                                    <div className="card" style={lClassname}>
                                        {this.state.data.premium.items.map((item, i) => {
                                            if (item.id !== null) {
                                                return (
                                                    <TileElevated key={item.cityname + "_" + item.id} content={item}/>
                                                )
                                            } else {
                                                return ''
                                            }
                                        })}
                                    </div>
                                </>
                            )
                            : ''
                        }
                        {this.state.data.results.items.length > 0
                            ? (
                                <>
                                    <div className="card" style={lClassname}>
                                        {this.state.data.results.items.map(item => {
                                            if (item.account_paket === "4") {
                                                return (<TileElevated key={item.cityname + "_" + item.id} content={item}/>)
                                            } else {
                                                return (<Tile key={item.cityname + "_" + item.id} content={item}/>)
                                            }
                                        })}
                                    </div>
                                </>
                            )
                            : ''
                        }

                        {this.state.data.outofbounds.items.length > 0
                            ? (
                                <>
                                    <div className="card-header border-0 px-1">
                                        <div className="row align-items-center">
                                            <div className="col"><h4 className="h5 mb-0 card-header-title">Weitere Ergebnisse</h4></div>
                                        </div>
                                    </div>
                                    <div className="card" style={lClassname}>
                                        {this.state.data.outofbounds.items.map(item => {
                                            if(item.id !== null) {
                                                if (item.account_paket === "4") {
                                                    return (<TileElevated key={item.cityname + "_" + item.id} content={item}/>)
                                                } else {
                                                    return (<Tile key={item.cityname + "_" + item.id} content={item}/>)
                                                }
                                            } else {
                                                return ''
                                            }
                                        })}
                                    </div>
                                </>
                            )
                            : ''
                        }

                        <AtlasPagination page={this.filters.page} paginationItems={this.state.data}
                                         updateFunction={this.pageUpdate}/>
                    </>
                );
            }

            return (
                <>
                    {results}
                </>
            );
        }
    }
}

export default Grid;