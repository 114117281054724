import React from "react";

class Range extends React.Component {
    constructor(props) {
        super(props);
        this.rangeUpdate = props.updateFunction;
        this.state = {
            currentRange: 5
        };
        this.setRange = this.setRange.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }

    setRange(range) {
        this.rangeUpdate(range);
        this.setState({currentRange: range});
    }

    render() {
        return (
            <>
                <div className="dropdown">
                    <a href="#!" className="small text-muted dropdown-toggle" data-toggle="dropdown"
                       aria-expanded="true">
                        <span>{this.state.currentRange}</span> km
                    </a>
                    <div className="dropdown-menu">
                        <a className="dropdown-item" href="#!" onClick={() => this.setRange(5)} data-value="5">5</a>
                        <a className="dropdown-item" href="#!" onClick={() => this.setRange(10)} data-value="10">10</a>
                        <a className="dropdown-item" href="#!" onClick={() => this.setRange(25)} data-value="25">25</a>
                        <a className="dropdown-item" href="#!" onClick={() => this.setRange(50)} data-value="50">50</a>
                        <a className="dropdown-item" href="#!" onClick={() => this.setRange(100)}
                           data-value="100">100</a>
                    </div>
                </div>
            </>
        );
    }
}

export default Range;