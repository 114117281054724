import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.min.css';
import Grid from "./Results/Default/Grid";
import Searchblock from "./Form/Searchblock";
import Filterlist from "./Form/Filterlist";
import ScrollableAnchor from 'react-scrollable-anchor'
import AtlasPagination from "./Results/Default/Pagination";
import Service from "../REST/Client";

class Frame extends React.Component {
    constructor() {
        super();
        let currentFilter = {filters: [], properties: [], radius: 5, page: 1};
        this.location = undefined;
        this.property = undefined;
        this.page = 1;
        this.lookUpData = this.lookUpData.bind(this);
        this.pageUpdateCallback = this.pageUpdateCallback.bind(this);
        this.rangeUpdateCallback = this.rangeUpdateCallback.bind(this);
        this.buttonIsLoadingState = false;
        this.itemsPerPage = 25;

        let matches = window.location.href.match(/(q=(.*)(?=&p=)&){0,1}p=(\d+)/);
        if (matches !== null) {
            if (matches[2] !== undefined) {
                currentFilter = JSON.parse(atob(matches[2]));
            }

            if (matches[3] !== undefined) {
                currentFilter.page = matches[3];
                this.page = matches[3];
            }
        }

        this.state = {
            allowNew: false,
            isLoading: false,
            multiple: false,
            formFilters: currentFilter,
            searchblockKey: 1,
            buttonIsLoadingState: true,
            results: {
                isLoading: true,
                hasResult: false,
                items: [],
                count: 0,
            },
            pages: 1,
        };

        this.service = new Service();
        if (matches === null) {
            this.fetchData();
        }
    }

    pageUpdateCallback(page) {
        let activeFilter = this.state.formFilters;
        if(activeFilter.page !== page) {
            activeFilter.page = page;
            this.page = page;
            this.createURI(activeFilter);
            this.fetchData(activeFilter);
            this.setState({formFilters: activeFilter});
        }
    }

    rangeUpdateCallback(range) {
        let activeFilter = this.state.formFilters;
        activeFilter.radius = range;
        this.createURI(activeFilter);
        this.fetchData(activeFilter);
        this.setState({formFilters: activeFilter});
    }

    lookUpData(data) {
        let activeFilter = this.state.formFilters;
        let lResults = this.state.results;
        lResults.isLoading = true;
        this.setState({results: lResults});
        let lSearchBlockKey = parseInt(this.state.searchblockKey);
        if (data.hasOwnProperty('inputs')) {
            if (data.inputs.hasOwnProperty('location')) {
                if(data.inputs.location === -1) {
                    data.inputs.location = -1;
                }
                else if(data.inputs.location !== undefined) {
                    if (Array.isArray(data.inputs.location)) {
                        data.inputs.location = data.inputs.location[0];
                    }
                } else {
                    data.inputs.location = [];
                }
            }
            activeFilter.properties = data.inputs;
            this.location = data.inputs.location;
            this.property = data.inputs.search;
            activeFilter.page = 1;
            this.page = 1;

            this.setState({formFilters: activeFilter, searchblockKey: lSearchBlockKey,buttonIsLoadingState: true});
        } else if (data.hasOwnProperty('filters')) {
            if (data.filters.hasOwnProperty("reset")) {
                activeFilter = {filters: [], properties: [], radius: 5, page: 1};
                this.location = [];
                this.property = [];

                lSearchBlockKey++;
            } else {
                activeFilter.filters = data.filters;
                activeFilter.page = 1;
            }

            this.page = 1;
            this.setState({formFilters: activeFilter, searchblockKey: lSearchBlockKey,buttonIsLoadingState: true});
        }
        this.createURI(activeFilter);
        this.fetchData(activeFilter);
    }

    fetchData(activeFilter) {
        if(activeFilter === undefined) {
            activeFilter = this.state.formFilters;
        }
        this.service.call('POST', 'v2/search/', activeFilter,
            json => {
                let reacCount = parseInt(json.premium.count);
                reacCount = reacCount + parseInt(json.results.count);
                let count = reacCount + parseInt(json.outofbounds.count);
                let lHasResult = false;
                if(count > 0) {
                    lHasResult = true;
                }
                this.setState({
                    results: {
                        isLoading: false,
                        hasResult: lHasResult,
                        items: json,
                        count: reacCount,
                    },
                    pages: Math.ceil(count/this.itemsPerPage),
                });
            });
    }

    createURI(filterSet) {

        if (filterSet === undefined) {
            filterSet = this.state.formFilters;
        }

        if (filterSet.properties.hasOwnProperty("location")) {
            if(Array.isArray(filterSet.properties.location)) {
                if(filterSet.properties.location.length > 0) {
                    filterSet.properties.location = filterSet.properties.location[0];
                    filterSet.properties.location.description = null;
                    filterSet.properties.location.coverphoto = null;
                }
            } else {
                if(filterSet.properties.location !== undefined) {
                    if(filterSet.properties.location.hasOwnProperty("description")) {
                        filterSet.properties.location.description = null;
                    }
                    if(filterSet.properties.location.hasOwnProperty("coverphoto")) {
                        filterSet.properties.location.coverphoto = null;
                    }
                }
            }
        }

        let linkData = btoa(JSON.stringify(filterSet));
        window.history.pushState(linkData, "AusbildungsAtlas Suche", "/suche/q=" + linkData + "&p=" + filterSet.page);
    }

    render() {
        let filterSidebar = "";
        let modalFilter = "";
        let mql = window.matchMedia('(max-width: 992px)');
        if (!mql.matches) {
            filterSidebar = (
                <div className="col-12 col-lg-3 d-none d-lg-block">
                    <Filterlist updateFunction={this.lookUpData} initialFilters={this.state.formFilters}/>
                </div>
            );
        } else {
            filterSidebar = (
                <div className="col-12 col-lg-3 d-none d-lg-block"></div>
            );
            modalFilter = (
                <div className="modal fade fixed-right" id="sidebarModalSearch" tabIndex="-1" role="dialog"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-vertical" role="document">
                        <div className="modal-content">
                            <div className="w-100 px-5 pt-5">
                                <h3>Filter</h3>
                                <button type="button" className="close float-right" data-dismiss="modal"
                                        aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="px-5 pt-5">
                                <Filterlist updateFunction={this.lookUpData} initialFilters={this.state.formFilters}/>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (

            <div className="searchframe">
                <div className="header bg-dark bg-cover bg-cover-top header-gradient pb-5">
                    <div className="container">
                        <nav aria-label="breadcrumb" className="done">
                            <ol className="breadcrumb small">
                                <li className="breadcrumb-item">
                                    <a href="https://www.ausbildungsatlas.de/" className="text-white">Home</a></li>
                                <li className="breadcrumb-item active text-white-75 aria-current=" page="">Suche</li>
                            </ol>
                        </nav>
                        <div className="header-body border-0 mt-md-2">
                            <div className="row align-items-end">
                                <div className="col">
                                    <h1 className="header-title text-white">Unternehmen suchen</h1>
                                </div>
                            </div>
                        </div>
                        <div className="header-footer pt-0 pb-2"></div>
                    </div>
                </div>
                <ScrollableAnchor id={'toSB'}>
                    <span></span>
                </ScrollableAnchor>
                <Searchblock key={this.state.searchblockKey} skey={this.state.searchblockKey} updateFunction={this.lookUpData} activeFilters={this.state.formFilters.properties} buttonIsLoadingState={this.state.buttonIsLoadingState}/>
                <div className="container py-5">
                    <div className="row">
                        {filterSidebar}
                        <div className="col-12 col-lg-9 search-results">
                            <Grid data={this.state.results} page={this.page} rangeUpdate={this.rangeUpdateCallback}/>
                            <AtlasPagination page={this.page}
                                             pages={this.state.pages}
                                             paginationItems={[]}
                                             updateFunction={this.pageUpdateCallback}/>
                        </div>
                    </div>
                </div>
                {modalFilter}
            </div>
        );
    }
}

export default Frame;


/*
                            <Grid
                                key={this.state.s}
                                filters={this.state.formFilters}
                                location={this.location}
                                search={this.property}
                                page={this.page}
                                pageUpdateCallback={this.pageUpdateCallback}
                                rangeUpdateCallback={this.rangeUpdateCallback}
                                resetFunctionCallback={this.lookUpData}
                                loadingUpdateCallback={this.loadingDoneState}/>
 */