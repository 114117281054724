import React from "react";
import NoResult from "./Tiles/NoResult";
import TileElevated from "./Tiles/TileElevated";
import Tile from "./Tiles/Tile";
import Range from "./Range";

class Grid extends React.Component {
    constructor(props) {
        super(props);
        this.data = [];
        this.rangeUpdateCallback = this.props.rangeUpdate;
        this.rangeUpdate = this.rangeUpdate.bind(this);
        this.currentRange = 5;
        this.page = props.page;
        this.perPage = 25;
        if(props.page === undefined) {
            this.page = 1;
        }
    }

    renderResults(items, title, canBeEmpty) {
        let output = [];
        let lClassname = {opacity: .25};
        if(!this.data.isLoading) {
            lClassname = {opacity: 1};
        }

        let lTitle = '';
        if(title !== null) {
            lTitle = (
                <div className="card-header border-0 px-1">
                    <div className="row align-items-center">
                        <div className="col">
                            <h4 className="h5 mb-0 card-header-title">{title}</h4>
                        </div>
                    </div>
                </div>
            );
        }

        if(items.items.length > 0) {
            output = (
                <>
                    {lTitle}
                    <div className="card" style={lClassname}>
                        {items.items.map(item => {
                            if (item.account_level === "4") {
                                return (<TileElevated key={item.urlname +"-"+ Math.random(0, 99)} content={item}/>)
                            } else {
                                return (<Tile key={item.urlname +"-"+ Math.random(0, 99)} content={item}/>)
                            }
                        })}
                    </div>
                </>
            );
        }

        if(items.items.length === 0 && canBeEmpty) {
            output = (
                <>
                    <div className="card border-0 px-1 card-transparent" style={lClassname}>
                        <p className="h4">Keine Ergebnisse in Umkreis von {this.currentRange} km</p>
                        <p className="mb-4">Leider haben wir im gewählten Umkreis keine Unternehmen
                        gefunden.</p></div>
                </>
            )
        }

        return output;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps.page !== this.page) {
            this.page = nextProps.page;
        }
        return true;
    }

    rangeUpdate(range) {
        this.rangeUpdateCallback(range);
        this.currentRange = range;
    }

    render() {
        this.data = this.props.data;
        if(this.data.isLoading && this.data.count === 0) {
            return <>Loading...</>
        }

        let lClassname = {opacity: 1};
        if(this.data.isLoading) {
            lClassname = {opacity: .25};
        }


        let lResults = <></>;
        let lPremium = <></>;
        let lOutofbounds = <></>;
        let canBeEmpty = false;

        if(this.page === 1 && this.data.items.results.count < this.perPage) {
            canBeEmpty = true;
        }

        if(this.page === 1 && this.data.items.premium.count > 0) {
            canBeEmpty = false;
        }

        if(!this.data.hasResult) {
            return <NoResult />
        } else {
            lPremium = this.renderResults(this.data.items.premium, null, false);
            lResults = this.renderResults(this.data.items.results, null, canBeEmpty);
            lOutofbounds = this.renderResults(this.data.items.outofbounds, "Weitere Ergebnisse", false);
        }

        let searchResultsLabel = " Suchergebnisse";
        if(this.data.count === 1) {
            searchResultsLabel = " Suchergebnis";
        }

        return (
            <>
                <div className="card-header border-0 px-1" style={lClassname}>
                    <div className="row align-items-center">
                        <div className="col">
                            <h4 className="h5 mb-0 card-header-title">
                                {(Math.ceil(this.data.count/this.perPage)) >= this.page
                                    ? (this.data.count + searchResultsLabel)
                                    : ''
                                }
                            </h4>
                        </div>
                        <div className="col-auto">
                            <Range updateFunction={this.rangeUpdate}/>
                        </div>
                        <div className="col-auto d-lg-none">
                            <a href="#!" className="small text-muted" data-toggle="modal" data-target="#sidebarModalSearch">
                                <i className="icon-filter-bold"></i> Filter
                            </a>
                        </div>
                    </div>
                </div>
                {lPremium}
                {lResults}
                {lOutofbounds}
            </>
        );
    }
}

export default Grid;