import React from "react";

class TileElevated extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: props.content
        }
    }

    render() {
        let frameClass = "card-body card-result border-bottom card-body-elevated-2";
        let badge = <span className="badge badge-warning mt-0 mt-md-0">★ Premium</span>;

        return (
            <div className={frameClass}>
                <div className="row align-items-center py-2 my-md-1">
                    <div className="col-auto col-md-auto">
                        <a href={"https://ausbildungsatlas.de/unternehmen/" + this.state.content.urlname + "/"}
                           className="avatar avatar-lg border rounded-circle overflow-hidden bg-white mb-3 mb-md-0 stretched-link">
                            {this.state.content.logo === ''
                                ? <span
                                    className="avatar-title rounded-circle">{this.state.content.name.substring(0, 1)}</span>
                                : <img src={"https://ausbildungsatlas.de" + this.state.content.logo}
                                       alt={this.state.content.name} className="avatar-img"/>
                            }
                        </a>
                    </div>
                    <div className="col pr-1 pr-md-3">
                        <div className="h6 header-pretitle text-muted mb-2">
                            {this.state.content.location !== undefined ? this.state.content.location : 'Unbekannt'}
                        </div>
                        <h3 className="h3 card-title mb-0">
                            <a className="text-break breakable mr-3 stretched-link"
                               href={"https://ausbildungsatlas.de/unternehmen/" + this.state.content.urlname + "/"}>{this.state.content.name}</a> {badge}
                        </h3>
                        <div
                            className="small mt-1">{this.state.content.industry !== '0' ? this.state.content.industry : ''} {this.state.content.employees !== '' ? <><span className="px-1 d-none d-md-inline"> •</span><span
                            className="d-none d-md-inline"> {this.state.content.employees} Mitarbeiter</span></> : ''}
                            {this.state.content.account_level > 3
                                ? <div className="small d-none d-md-inline-block text-body mt-2">{this.state.content.description}...</div>
                                : ''
                            }
                        </div>
                    </div>
                    <div className="col-auto d-none d-md-inline-block">
                        <a href={"https://ausbildungsatlas.de/unternehmen/" + this.state.content.urlname + "/"}
                           className="btn btn-sm btn-white d-none d-md-inline-block">Ansehen</a>
                    </div>
                </div>
                {this.state.content.account_level > 3
                    ? (
                        <div className="row d-md-none">
                            <div className="col pr-1 pr-md-3">
                                <div className="small text-body mt-2">
                                    {this.state.content.description}...
                                </div>
                            </div>
                        </div>
                    )
                    : ''
                }
            </div>

        );
    }
}

export default TileElevated;