class Service {

    constructor() {
        //this.url = 'http://127.0.0.1:8000/';
        this.url = 'https://api.ausbildungsatlas.de/';

        this.callProps = {
            url: this.url,
            header: [
                { name: 'api_token', 'value': '...',},
                { name: 'api_secret', 'value': '...',}
            ]
        }
    }

    call(method, path, data, callback) {

        let initVars = null;

        if(method === 'POST' || method === 'PUT' || method === 'OPTIONS') {
            initVars = {
                method: method,
                body: JSON.stringify(data),
            };
        }

        fetch(this.callProps.url + path, initVars)
            .then(response => response.json())
            .then(callback)
            .catch(reason => {
                console.log(reason);
            })
    }
}

export default Service;