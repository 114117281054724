import React from "react";

class Coverphoto extends React.Component {
    constructor(props) {
        super(props);

        this.location = {
            id: 0,
            name: "",
        }

        if(props.location !== undefined) {
            this.location = props.location;
        }

        this.state = {
            location: this.location,
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.location !== undefined) {
            if (nextProps.location.id !== this.location.id) {
                this.location = nextProps.location;
                this.setState({location: this.location});
                return true;
            }
        }
        return false;
    }

    render() {
        let title = "AusbildungsAtlas Deutschland";
        let subheadline = "Entdecke Unternehmen, die Ausbildungsplätze anbieten.";
        let breadcrumb = "Deutschland";
        let coverphoto = 'linear-gradient(to top, rgba(21, 46, 77, 0.5), rgba(21, 46, 77, 1))';
        if(this.location.id !== 0) {
            if(this.location.coverphoto !== undefined) {
                coverphoto = `linear-gradient(to top, rgba(21, 46, 77, 0.5), rgba(21, 46, 77, 1)), url(${this.location.coverphoto})`;
            }
            if(this.location.name !== undefined) {
                title = `Ausbildung in ${this.location.name}`;
                breadcrumb = this.location.name;
                subheadline = `Entdecke Unternehmen, die Ausbildungsplätze in ${this.location.name} und Umgebung anbieten.`;
            }
        }

           let output = (
               <>
                <div className="header bg-dark bg-cover bg-cover-top pb-5" style={{backgroundImage: coverphoto}}>
                    <div className="container">
                        <nav aria-label="breadcrumb" className="done">
                            <ol className="breadcrumb small">
                                <li className="breadcrumb-item"><a href="https://www.ausbildungsatlas.de" className="text-white text-white">Home</a></li>
                                <li className="breadcrumb-item active text-white-75 aria-current=" page="">{breadcrumb}</li>
                            </ol>
                        </nav>
                        <div className="header-body border-0 pt-5 pt-xxl-6 py-xxx-7">
                            <div className="row align-items-end">
                                <div className="col">
                                    <h6 className="header-pretitle text-muted d-none">
                                        Übersicht
                                    </h6>
                                    <h1 className="header-title text-white">
                                        {title}
                                    </h1>
                                    <p className="text-white-90 mt-3 mt-md-2 mb-1">{subheadline}</p>
                                </div>
                            </div>
                        </div>
                        <div className="header-footer pt-0 pb-2"></div>
                    </div>
                </div>
            </>);


        return (output)
    }
}

export default Coverphoto;