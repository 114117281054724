import React from "react";

class AtlasPagination extends React.Component {
    render() {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <div className="row mt-5">
                    <a href="/suche/" className="btn btn-primary m-auto">
                        Alle Unternehmen anzeigen <i className="icon-arrow-right-bold small ml-2"></i>
                    </a>
                </div>
            </div>
        );
    }
}

export default AtlasPagination;