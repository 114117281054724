import React from "react";
import {Pagination} from "react-bootstrap";
import {goToAnchor} from "react-scrollable-anchor";

class AtlasPagination extends React.Component {
    constructor(props) {
        super(props);
        this.data = props.paginationItems;
        this.pageLimit = 25;
        this.pages = props.pages;
        this.page = parseInt(props.page);
        this.pageUpdateFunction = props.updateFunction;
        this.updatePagination = this.updatePagination.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.state = {};
        this.pagination = {
            page: this.page,
            prevHidden: true,
            nextHidden: false,
        }

        if(this.pages > 2) {
            this.pagination.nextHidden = false;
        } else {
            this.pagination.nextHidden = true;
        }

        this.updatePagination();
    }

    updatePagination() {
        if (this.page === 1) {
            this.pagination.prevHidden = true;
            if(this.pages > 1) {
                this.pagination.nextHidden = false;
            } else {
                this.pagination.nextHidden = true;
            }
        } else if (this.page === this.pages) {
            this.pagination.prevHidden = false;
            this.pagination.nextHidden = true;
        } else {
            this.pagination.prevHidden = false;
            this.pagination.nextHidden = false;
        }

        this.pagination.page = this.page;
        this.pageUpdateFunction(this.page);
    }

    nextPage() {
        if (this.page < this.pages) {
            this.page += 1;
            this.updatePagination();
            this.setState(this.pagination);

            goToAnchor('toSB');
            this.pageUpdateFunction(this.page);
        }
    }

    lastPage() {
        if (this.page >= 2) {
            this.page -= 1;

            this.updatePagination();
            this.setState(this.pagination);

            goToAnchor('toSB');
            this.pageUpdateFunction(this.page);
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextState.page !== this.state.page) {
            return true;
        }
        if (nextProps.page !== this.state.page) {
            this.page = parseInt(nextProps.page);
            this.data = nextProps.paginationItems;
            this.pages=nextProps.pages;

            if (this.page === 1) {
                this.pagination.prevHidden = true;
                if(this.pages > 1) {
                    this.pagination.nextHidden = false;
                } else {
                    this.pagination.nextHidden = true;
                }
            } else if (this.page === this.pages) {
                this.pagination.prevHidden = false;
                this.pagination.nextHidden = true;
            } else {
                this.pagination.prevHidden = false;
                this.pagination.nextHidden = false;
            }
            return true;
        }
        return false;
    }

    render() {
        if(this.pages === 1) {
            return '';
        } else {
            return (
                <div className="d-flex justify-content-center align-items-center mt-4">
                    <Pagination>
                        {!this.pagination.prevHidden
                            ? <Pagination.Prev href='#toSB' onClick={this.lastPage}/>
                            : ''
                        }
                        <Pagination.Item key={this.page} active={true}>{this.page}</Pagination.Item>
                        {!this.pagination.nextHidden
                            ? <Pagination.Next onClick={this.nextPage}/>
                            : ''
                        }
                    </Pagination>
                </div>
            );
        }

    }
}

export default AtlasPagination;