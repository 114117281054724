import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.min.css';
import Grid from "./Results/Default/Grid";
import Searchblock from "./Form/City/Searchblock";
import Filterlist from "./Form/Filterlist";
import ScrollableAnchor from 'react-scrollable-anchor'
import Coverphoto from "./Coverphoto";
import Service from "../REST/Client";
import AtlasPagination from "./Results/Default/Pagination";

class FrameCity extends React.Component {
    constructor() {
        super();
        let currentFilter = {filters: [], properties: [], radius: 5, page: 1};
        this.urlName = undefined;
        this.location = undefined;
        this.property = undefined;
        this.page = 1;
        this.lookUpData = this.lookUpData.bind(this);
        this.pageUpdateCallback = this.pageUpdateCallback.bind(this);
        this.rangeUpdateCallback = this.rangeUpdateCallback.bind(this);
        this.frameInputAttach = this.frameInputAttach.bind(this);
        this.loadingDoneState = this.loadingDoneState.bind(this);
        this.loc = window.location.href;
        this.typeaheads = {search: null, location: null};
        this.service = new Service();
        this.itemsPerPage = 25;

        let matches = window.location.href.match(/([^/]*)\/(q=(.*)(?=&p=)&){0,1}p=(\d+)/);
        if (matches !== null) {
            this.urlName = matches[1];
            if (matches[3] !== undefined) {
                currentFilter = JSON.parse(atob(matches[3]));
            }

            if (matches[4] !== undefined) {
                currentFilter.page = matches[4];
            }
        } else {
            matches = window.location.href.match(/\/\/(.*)\/([^/]*)\//);
            if(matches !== null) {
                this.urlName = matches[2];
            }
        }

        this.state = {
            allowNew: false,
            isLoading: false,
            multiple: false,
            formFilters: currentFilter,
            searchblockKey: 1,
            cpkey: 1,
            buttonIsLoadingState: true,
            results: {
                isLoading: true,
                hasResult: false,
                items: [],
                count: 0,
            },
            pages: 1,
        };

        if(this.urlName !== undefined) {
            if(this.urlName !== "suche" && this.urlName !== undefined) {
                this.service.call('GET', `v1/search/suggestions/city/urlname/?query=${this.urlName}`, null, json => {
                    if(json.hasOwnProperty("items")) {
                        if(json.items.hasOwnProperty("cities")) {
                            if(json.items.cities.length > 0) {
                                this.location = json.items.cities[0];
                                let lSearchBlockKey = this.state.searchblockKey++;
                                let lLocation = this.location;

                                if(lLocation.description !== '') {
                                    lLocation.description = '';
                                }

                                if(currentFilter.properties.hasOwnProperty("search")) {
                                    currentFilter.properties = {"search": currentFilter.properties.search, "location": this.location};
                                } else {
                                    currentFilter.properties = {"search": undefined, "location": this.location};
                                }
                                this.setState({location: this.location, currentFilter: currentFilter, searchblockKey: lSearchBlockKey, cpkey: lSearchBlockKey});
                            }
                        }
                    }
                    //this.createURI(this.state.formFilters);
                    this.fetchData(this.state.formFilters);
                });
            }
        }
    }

    pageUpdateCallback(page) {
        let activeFilter = this.state.formFilters;
        if(parseInt(activeFilter.page) !== page) {
            activeFilter.page = page;
            this.page = page;
            this.createURI(activeFilter);
            this.fetchData(activeFilter);
            console.log("2");
            this.setState({formFilters: activeFilter});
        }
    }

    rangeUpdateCallback(range) {
        let activeFilter = this.state.formFilters;
        activeFilter.radius = range;
        this.createURI(activeFilter);
        this.fetchData(activeFilter);
        this.setState({formFilters: activeFilter});
    }

    lookUpData(data) {
        let activeFilter = this.state.formFilters;
        let lResults = this.state.results;
        lResults.isLoading = true;
        this.setState({results: lResults});
        let lSearchBlockKey = parseInt(this.state.searchblockKey);
        if (data.hasOwnProperty('inputs')) {
            if (data.inputs.hasOwnProperty('location')) {
                if(data.inputs.location === -1) {
                    data.inputs.location = -1;
                }
                else if(data.inputs.location !== undefined) {
                    if (Array.isArray(data.inputs.location)) {
                        data.inputs.location = data.inputs.location[0];
                    }
                } else {
                    data.inputs.location = [];
                }
            }
            activeFilter.properties = data.inputs;
            this.location = data.inputs.location;
            this.property = data.inputs.search;
            activeFilter.page = 1;
            this.page = 1;

            this.setState({formFilters: activeFilter, searchblockKey: lSearchBlockKey,buttonIsLoadingState: true});
        } else if (data.hasOwnProperty('filters')) {
            if (data.filters.hasOwnProperty("reset")) {
                activeFilter = {filters: [], properties: {search: [], location: activeFilter.properties.location}, radius: 5, page: 1};
                this.location = activeFilter.properties.location;
                this.property = [];

                lSearchBlockKey++;
            } else {
                activeFilter.filters = data.filters;
                activeFilter.page = 1;
            }

            this.page = 1;
            this.setState({formFilters: activeFilter, searchblockKey: lSearchBlockKey,buttonIsLoadingState: true});
        }

        this.createURI(activeFilter);
        this.fetchData(activeFilter);
    }

    frameInputAttach(input) {
    }

    createURI(filterSet) {

        if (filterSet === undefined) {
            filterSet = this.state.formFilters;
        }

        if (filterSet.properties.hasOwnProperty("location")) {
            if(Array.isArray(filterSet.properties.location)) {
                if(filterSet.properties.location.length > 0) {
                    filterSet.properties.location = filterSet.properties.location[0];
                    filterSet.properties.location.description = null;
                    filterSet.properties.location.coverphoto = null;
                }
            } else {
                if(filterSet.properties.location !== undefined) {
                    if(filterSet.properties.location.hasOwnProperty("description")) {
                        filterSet.properties.location.description = null;
                    }
                    if(filterSet.properties.location.hasOwnProperty("coverphoto")) {
                        filterSet.properties.location.coverphoto = null;
                    }
                }
            }
        }

        let linkData = btoa(JSON.stringify(filterSet));
        window.history.pushState(linkData, "AusbildungsAtlas " + this.location.name, "/" + this.location.urlname + "/q=" + linkData + "&p=" + filterSet.page);
    }

    fetchData(activeFilter) {
        if(activeFilter === undefined) {
            activeFilter = this.state.formFilters;
        }
        this.service.call('POST', 'v2/search/', activeFilter,
            json => {
                let reacCount = parseInt(json.premium.count);
                reacCount = reacCount + parseInt(json.results.count);
                let count = reacCount + parseInt(json.outofbounds.count);
                let lHasResult = false;
                if(count > 0) {
                    lHasResult = true;
                }
                this.setState({
                    results: {
                        isLoading: false,
                        hasResult: lHasResult,
                        items: json,
                        count: reacCount,
                    },
                    pages: Math.ceil(count/this.itemsPerPage),
                });
            });
    }

    loadingDoneState() {

    }

    render() {
        if(this.location === undefined) {
            return (
                <div className="searchframe citysearch">
                    <div className="header"></div>
                    <div className="container py-5"></div>
                </div>
            );
        } else {
            let filterSidebar = "";
            let modalFilter = "";
            let mql = window.matchMedia('(max-width: 992px)');
            if (!mql.matches) {
                filterSidebar = (
                    <div className="col-12 col-lg-3 d-none d-lg-block">
                        <Filterlist updateFunction={this.lookUpData} initialFilters={this.state.formFilters}/>
                    </div>
                );
            } else {
                filterSidebar = (
                    <div className="col-12 col-lg-3 d-none d-lg-block"></div>
                );
                modalFilter = (
                    <div className="modal fade fixed-right" id="sidebarModalSearch" tabIndex="-1" role="dialog"
                         aria-hidden="true">
                        <div className="modal-dialog modal-dialog-vertical" role="document">
                            <div className="modal-content">
                                <div className="w-100 px-5 pt-5">
                                    <h3>Filter</h3>
                                    <button type="button" className="close float-right" data-dismiss="modal"
                                            aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="px-5 pt-5">
                                    <Filterlist updateFunction={this.lookUpData} initialFilters={this.state.formFilters}/>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }

            return (
                <div className="searchframe citysearch">
                    <Coverphoto key={"cp_" + this.state.cpkey} location={this.location}/>
                    <ScrollableAnchor id={'toSB'}>
                        <span></span>
                    </ScrollableAnchor>
                    <Searchblock key={this.state.searchblockKey} updateFunction={this.lookUpData} activeFilters={this.state.formFilters.properties} inputAttach={this.frameInputAttach}/>
                    <div className="container py-5">
                        <div className="row">
                            {filterSidebar}
                            <div className="col-12 col-lg-9 search-results">
                                <Grid data={this.state.results} page={this.page} rangeUpdate={this.rangeUpdateCallback}/>
                                <AtlasPagination page={this.page}
                                                 pages={this.state.pages}
                                                 paginationItems={[]}
                                                 updateFunction={this.pageUpdateCallback}/>
                            </div>
                        </div>
                    </div>
                    {modalFilter}
                </div>
            );
        }
    }
}

export default FrameCity;