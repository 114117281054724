import React from "react";

class NoResult extends React.Component {
    render() {
        return (<>
            <div className="card" style={{opacity: 1}}>
                <div className="card-body">
                    <h4 className="mb-4">Keine Ergebnisse</h4>
                    <p className="mb-4">
                        Leider haben wir zu Deinen Suchkriterien keine Unternehmen gefunden. <span role="img" aria-label="Traurig">😭</span>
                    </p>
                    <button type="reset" className="btn btn-white text-primary" onClick={this.resetUpdate}><i className="icon-refresh-bold"></i> <span>Suche zurücksetzen</span></button>
                </div>
            </div>
        </>);
    }
}

export default NoResult;